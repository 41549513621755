import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import useWindowDimensions from '@/helpers/use-window-dimensions';

import { uploadPetAvatar } from '@/actions/pet';
import { loadUser } from '@/actions/user';
import Button from '@/atoms/button';
import ImageUploader from '@/atoms/image-uploader';
import { capitalizeFirstLetter } from '@/util';
import { requestFulfilled, requestOngoing } from '@/store/reducers/ui';
import { setActivePet } from '@/store/reducers/pet-appointments';

export default function UploadPetAvatarDetails() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [image, saveImage] = useState<FormData>();
  const { activePet } = useAppSelector((state) => state.petAppointments);
  const advancedSearchPetUuid = useAppSelector(
    (state) => state.advancedSearch.petUuid,
  );
  const animalUuid = activePet.uuid || advancedSearchPetUuid;
  const isMobile = width < 600;

  const onNext = async (e: any) => {
    e.preventDefault();

    if (!image) {
      loadUserInfo();
    } else {
      dispatch(requestOngoing());
      const uploadRequest = await dispatch(uploadPetAvatar(image, animalUuid));
      dispatch(requestFulfilled());

      if (uploadRequest?.success) {
        console.log(uploadRequest.data?.data);
        await dispatch(setActivePet(uploadRequest.data?.data));
        loadUserInfo();
      }
    }
  };

  const loadUserInfo = async () => {
    dispatch(requestOngoing());
    const loadUserRequest = await dispatch(loadUser());
    dispatch(requestFulfilled());
    if (loadUserRequest?.success) {
      navigate(`/user/${loadUserRequest.data?.data.uuid}/pet/${animalUuid}`);
    }
  };

  return (
    <div
      className={classNames(
        'flex-column align-center text-center',
        isMobile && 'upload-avatar-page-mobile',
      )}
    >
      <Helmet title="Pet avatar | PawCare" />
      <h1>{`Add a photo of ${capitalizeFirstLetter(activePet.name)}`}</h1>
      <form
        onSubmit={onNext}
        className={classNames(
          'flex-column align-center gap-12',
          isMobile && 'w-100',
        )}
      >
        <div className="mobile-container mb-32">
          <ImageUploader
            saveImage={saveImage}
            placeholder="pawFilled"
            cameraVariant
            showChangeImageText
            showEditIcon={false}
            fileName="upload_file"
            size="big"
            variant="profile"
            className="margin-center"
          />
        </div>
        <div
          className={classNames(
            isMobile ? 'mobile-main-action-btn' : 'margin-center',
          )}
        >
          <Button
            type="submit"
            text="Next"
            variant="default"
            size="medium"
            className="w-100"
          />
        </div>
        {!isMobile && (
          <button
            className="text-grey-dark pointer b-transparent border-none"
            onClick={loadUserInfo}
          >
            Skip this step
          </button>
        )}
      </form>
    </div>
  );
}
