import { FormEvent, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '@/helpers/use-window-dimensions';

import { logInMfa, mfaInit } from '@/actions/authentication';
import Button from '@/atoms/button';
import Input from '@/atoms/input';
import toast from '@/atoms/toast';
import { prettifyPhoneNumber } from '@/util';
import { requestFulfilled, requestOngoing } from '@/store/reducers/ui';
import { loadBootstrap } from '@/actions';
import { clearForm } from '@/store/reducers/mfa-login-form';

export default function LoginMfaInfo() {
  const itemsRef = useRef<(HTMLInputElement | null)[]>([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const mfaLoginFormData = useAppSelector((state) => state.mfaLoginForm.data);
  const { countryCodeId, phoneNumber } = mfaLoginFormData;
  const { width } = useWindowDimensions();
  const isMobile = width < 600;
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [pastedFocusIndex, setPastedFocusIndex] = useState<number | null>(null);

  useEffect(() => {
    if (!phoneNumber) {
      navigate(localStorage.getItem('homepageUrl') || '/');
    }
  }, []);

  const onResendCode = async () => {
    dispatch(requestOngoing());
    const mfaRequest = await dispatch(
      mfaInit(
        { countryCodeId: countryCodeId.id, phoneNumber },
        { showAPIerror: true },
      ),
    );
    dispatch(requestFulfilled());
    if (mfaRequest?.success) {
      toast({
        text: `Verification code resent to
      ${prettifyPhoneNumber(phoneNumber, countryCodeId?.code)}.
    `,
      });
    }
  };

  const onPhoneValidation = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(requestOngoing());
    const mfaLoginRequest = await dispatch(
      logInMfa({
        countryCodeId: countryCodeId.id,
        phoneNumber,
        code: code.join(''),
      }),
    );
    dispatch(requestFulfilled());
    if (mfaLoginRequest?.success) {
      dispatch(clearForm());
      const { token } = mfaLoginRequest.data.data;
      localStorage.setItem('token', token);
      dispatch(loadBootstrap());
      navigate(localStorage.getItem('homepageUrl') || '/');
    }
  };

  const onChange = (digit: string, index: number) => {
    const isEmptyDigit = digit === '';
    const updatedCode = code.map((el, i) => {
      if (i === index) {
        return digit;
      }
      return el;
    });
    setCode(updatedCode);
    if (index === 5 && isEmptyDigit) {
      const item = itemsRef.current?.[index - 1] as any;
      item?.focus();
    }
    if (index <= 4) {
      const focusIndex =
        pastedFocusIndex || (isEmptyDigit ? index - 1 : index + 1);

      const item = itemsRef.current?.[focusIndex] as any;
      item?.focus();
      setPastedFocusIndex(null);
    }
  };

  const onPaste = (event: ClipboardEvent) => {
    const copiedCode = event?.clipboardData?.getData('text') || '';
    const updatedCode = copiedCode.split('').splice(0, 6);
    setPastedFocusIndex(updatedCode.length);
    setCode(updatedCode);
  };

  return (
    <div className="mfa">
      <h1 className="mobile-page-title mb-32">Phone verification</h1>
      <form onSubmit={onPhoneValidation} className="h-100">
        <div className="mobile-page-container h-100">
          <div className="flex-row w-100 justify-between mfa-code mb-16">
            <Input
              onPaste={onPaste}
              type={isMobile ? 'number' : 'text'}
              ref={(ref) => itemsRef.current.push(ref)}
              id="digit-0"
              maxLength="1"
              onChange={(value) => onChange(value, 0)}
              value={code[0]}
              autoComplete="one-time-code"
              variant="white"
            />
            <Input
              onPaste={onPaste}
              type={isMobile ? 'number' : 'text'}
              ref={(ref) => itemsRef.current.push(ref)}
              id="digit-1"
              maxLength="1"
              onChange={(value) => onChange(value, 1)}
              value={code[1]}
              autoComplete="one-time-code"
              variant="white"
            />
            <Input
              onPaste={onPaste}
              type={isMobile ? 'number' : 'text'}
              ref={(ref) => itemsRef.current.push(ref)}
              id="digit-2"
              maxLength="1"
              onChange={(value) => onChange(value, 2)}
              value={code[2]}
              autoComplete="one-time-code"
              variant="white"
            />
            <Input
              onPaste={onPaste}
              type={isMobile ? 'number' : 'text'}
              ref={(ref) => itemsRef.current.push(ref)}
              id="digit-3"
              maxLength="1"
              onChange={(value) => onChange(value, 3)}
              value={code[3]}
              autoComplete="one-time-code"
              variant="white"
            />
            <Input
              onPaste={onPaste}
              type={isMobile ? 'number' : 'text'}
              ref={(ref) => itemsRef.current.push(ref)}
              id="digit-4"
              maxLength="1"
              onChange={(value) => onChange(value, 4)}
              value={code[4]}
              autoComplete="one-time-code"
              variant="white"
            />
            <Input
              onPaste={onPaste}
              type={isMobile ? 'number' : 'text'}
              ref={(ref) => itemsRef.current.push(ref)}
              id="digit-5"
              maxLength="1"
              onChange={(value) => onChange(value, 5)}
              value={code[5]}
              autoComplete="one-time-code"
              variant="white"
            />
          </div>
          <span>
            We&apos;ve sent a text message to{' '}
            {prettifyPhoneNumber(phoneNumber, countryCodeId?.code)}. You have 10
            minutes to enter the code. Having trouble?{' '}
            <Button
              className="inline-display"
              variant="link"
              onClick={onResendCode}
              text="Resend code"
              size="medium"
              disableLoadingState
            />
            .
          </span>
        </div>
        <div className="mobile-main-action-btn">
          <Button
            className="my-32"
            text="Next"
            type="submit"
            variant={code.includes('') ? 'disabled' : 'default'}
          />
        </div>
      </form>
    </div>
  );
}
