import './landing.scss';

import { useAppDispatch, useAppSelector } from '@/hooks';
import classNames from 'classnames';
import useWindowDimensions from '@/helpers/use-window-dimensions';

import { logOut } from '@/actions/authentication';
import Button from '@/atoms/button';
import bgImg from '@/images/home.webp';

export default function Landing({ children, removeOutterPadding }: any) {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state) => state.user);
  const { width } = useWindowDimensions();
  const isMobile = width < 600;

  if (isMobile)
    return (
      <div
        className={classNames('l-centered w-100', removeOutterPadding && 'p-0')}
      >
        {children}
      </div>
    );

  return (
    <div className="landing">
      <div
        className="landing-container l-left l-split"
        style={{ backgroundImage: `url(${bgImg})` }}
      >
        <div className="landing-container-centered">
          <div className="landing-container-centered-block">
            <h3 className="strong l-title">Welcome to PawCare</h3>
            <p className="landing-description">
              Create your account and start managing your pet&apos;s care today.
            </p>
          </div>
        </div>
        {token && (
          <Button
            text="Log out"
            className="landing-logout pos-absolute"
            size="small"
            variant="transparent"
            onClick={() => logOut(dispatch)}
          />
        )}
      </div>
      <div className="l-split l-right">
        <div className="l-centered">{children}</div>
      </div>
    </div>
  );
}
