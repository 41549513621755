import adminUser from './admin-user';
import advancedSearch from './advanced-search';
import authentication from './authentication';
import createPetForm from './create-pet-form';
import hardInventory from './hard-inventory';
import invoice from './invoice';
import lastUserAppointment from './last-user-appointment';
import location from './location';
import main from './main';
import mfaLoginForm from './mfa-login-form';
import petAppointments from './pet-appointments';
import provider from './provider';
import providerCalendar from './provider-calendar';
import providers from './providers';
import providersSearch from './providers-search';
import requestAppointmentForm from './request-appointment-form';
import signupForm from './signup-form';
import ui from './ui';
import user from './user';

const rootReducer = {
  adminUser,
  advancedSearch,
  authentication,
  createPetForm,
  hardInventory,
  invoice,
  lastUserAppointment,
  location,
  main,
  mfaLoginForm,
  petAppointments,
  provider,
  providerCalendar,
  providers,
  providersSearch,
  requestAppointmentForm,
  signupForm,
  ui,
  user,
};

export default rootReducer;
