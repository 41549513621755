import './user-details.scss';

import classNames from 'classnames';

import DefaultAvatar from '@/atoms/default-avatar';

interface UserDetailsProps {
  item: any;
  className?: string;
  avatarSize?: 'medium' | 'big' | 'small' | 'extra-small' | 'extra-large';
}

export function UserDetails({
  item,
  className,
  avatarSize = 'medium',
}: UserDetailsProps) {
  if (!item) return;
  return (
    <div
      className={classNames('flex-row user-details text-left gap-4', className)}
    >
      <DefaultAvatar
        className="user-details-avatar"
        size={avatarSize}
        avatar={item.links?.image?.desktop?.large}
      />
      <div className="flex-column">
        <h4 className="text-black">{item.name}</h4>
        <p className="user-details-address">{item.address1}</p>
      </div>
    </div>
  );
}
