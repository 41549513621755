import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '@/helpers/use-window-dimensions';

import { logIn, mfaInit, mfaVerify, signUp } from '@/actions/authentication';
import Button from '@/atoms/button';
import Input from '@/atoms/input';
import toast from '@/atoms/toast';
import { prettifyPhoneNumber } from '@/util';
import { trackSignup } from '@/util/tracking/events';
import { loadBootstrap } from '@/actions';

export default function MFAInfo() {
  const itemsRef = useRef<(HTMLInputElement | null)[]>([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const signupFormData = useAppSelector((state) => state.signupForm.data);
  const { countryCodeId, phoneNumber, email, password } = signupFormData;
  const { width } = useWindowDimensions();
  const isMobile = width < 600;
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [pastedFocusIndex, setPastedFocusIndex] = useState(null);
  const inviteCode = sessionStorage.getItem('inviteCode');

  useEffect(() => {
    if (!phoneNumber) {
      navigate(localStorage.getItem('homepageUrl') || '/');
    }
  }, []);

  const onResendCode = async (e: any) => {
    e.preventDefault();
    const res = await dispatch(
      mfaInit({ countryCodeId: countryCodeId.id, phoneNumber }),
    );
    if (res?.success) {
      toast({
        text: `Verification code resent to
      ${prettifyPhoneNumber(phoneNumber, countryCodeId?.code)}.
    `,
      });
    }
  };

  const onPhoneValidation = (e: any) => {
    e.preventDefault();
    dispatch(
      mfaVerify({
        code: code.join(''),
        countryCodeId: countryCodeId.id,
        phoneNumber,
      }),
    )?.then(({ success, data }) => {
      let payload = {
        ...signupFormData,
        countryCodeId: countryCodeId.id,
        phoneVerificationToken: data.phoneVerificationToken,
      } as any;
      if (inviteCode) {
        payload = {
          ...payload,
          inviteCode,
        };
      }
      if (success) {
        dispatch(signUp(payload))?.then(({ success }) => {
          dispatch(trackSignup());
          if (success) {
            dispatch(logIn({ email, password }))?.then(({ success, data }) => {
              if (success) {
                const { token } = data.data;
                localStorage.setItem('token', token);
                dispatch(loadBootstrap());
              }
            });
          }
        });
      }
    });
  };

  const onChange = (digit: string, index: number) => {
    const isEmptyDigit = digit === '';
    const updatedCode = code.map((el, i) => {
      if (i === index) {
        return digit;
      }
      return el;
    });
    setCode(updatedCode);
    if (index === 5 && isEmptyDigit) {
      const item = itemsRef.current?.[index - 1] as any;
      item?.focus();
    }
    if (index <= 4) {
      const focusIndex =
        pastedFocusIndex || (isEmptyDigit ? index - 1 : index + 1);

      const item = itemsRef.current?.[focusIndex] as any;
      item?.focus();
      setPastedFocusIndex(null);
    }
  };

  const onPaste = (event: any) => {
    const copiedCode = event.clipboardData.getData('text');
    const updatedCode = copiedCode.split('').splice(0, 6);
    setPastedFocusIndex(updatedCode.length);
    setCode(updatedCode);
  };

  return (
    <div className="mfa">
      <h1 className="mobile-page-title">Phone verification</h1>
      <form onSubmit={onPhoneValidation} className="h-100">
        <div className="mobile-page-container h-100">
          <p className="bold mb-15">Verification code</p>
          <div className="flex-row w-100 justify-between mfa-code">
            <Input
              onPaste={onPaste}
              type={isMobile ? 'number' : 'text'}
              ref={(ref) => itemsRef.current.push(ref)}
              id="digit-0"
              maxLength="1"
              onChange={(value) => onChange(value, 0)}
              value={code[0]}
              autoComplete="one-time-code"
              variant="white"
            />
            <Input
              onPaste={onPaste}
              type={isMobile ? 'number' : 'text'}
              ref={(ref) => itemsRef.current.push(ref)}
              id="digit-1"
              maxLength="1"
              onChange={(value) => onChange(value, 1)}
              value={code[1]}
              autoComplete="one-time-code"
              variant="white"
            />
            <Input
              onPaste={onPaste}
              type={isMobile ? 'number' : 'text'}
              ref={(ref) => itemsRef.current.push(ref)}
              id="digit-2"
              maxLength="1"
              onChange={(value) => onChange(value, 2)}
              value={code[2]}
              autoComplete="one-time-code"
              variant="white"
            />
            <Input
              onPaste={onPaste}
              type={isMobile ? 'number' : 'text'}
              ref={(ref) => itemsRef.current.push(ref)}
              id="digit-3"
              maxLength="1"
              onChange={(value) => onChange(value, 3)}
              value={code[3]}
              autoComplete="one-time-code"
              variant="white"
            />
            <Input
              onPaste={onPaste}
              type={isMobile ? 'number' : 'text'}
              ref={(ref) => itemsRef.current.push(ref)}
              id="digit-4"
              maxLength="1"
              onChange={(value) => onChange(value, 4)}
              value={code[4]}
              autoComplete="one-time-code"
              variant="white"
            />
            <Input
              onPaste={onPaste}
              type={isMobile ? 'number' : 'text'}
              ref={(ref) => itemsRef.current.push(ref)}
              id="digit-5"
              maxLength="1"
              onChange={(value) => onChange(value, 5)}
              value={code[5]}
              autoComplete="one-time-code"
              variant="white"
            />
          </div>
          <div className="mt-25">
            We’ve sent a text message to{' '}
            {prettifyPhoneNumber(phoneNumber, countryCodeId?.code)}. You have 10
            minutes to enter the code. Having trouble?{' '}
            <button
              onClick={onResendCode}
              className="text-orange pointer no-style-btn f16"
            >
              Resend code
            </button>
            .
          </div>
        </div>
        <div className="mobile-main-action-btn">
          <Button
            className="mt-35 mb-20"
            text="Next"
            type="submit"
            variant={code.includes('') ? 'disabled' : 'default'}
          />
        </div>
      </form>
    </div>
  );
}
