import './navbar.scss';

import { useState } from 'react';
import ReactModal from 'react-modal';
import { useAppSelector } from '@/hooks';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import useWindowDimensions from '@/helpers/use-window-dimensions';

import Icon from '@/atoms/icon';
import SearchBar from '@/atoms/search-bar';
import DaycareModal from '@/organisms/daycare-modal';
import { checkoutFlowURLs } from '@/util/constants';

import DesktopItems from './navbar-items/desktop-items';
import MobileItems from './navbar-items/mobile-items';

function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useAppSelector((state) => state.user);
  const { isMobileMenuOpen } = useAppSelector((state) => state.ui);
  const [isDaycareModalOpen, openDaycareModal] = useState(false);
  const { pathname } = location;
  const { width } = useWindowDimensions();
  const isMobile = token ? width < 900 : width < 1100;
  const isHomepage = pathname === '/';
  const isProviderProfile = pathname.includes('/p/');
  const isCheckoutPage = checkoutFlowURLs.includes(pathname);
  const hideSearchBarUrls = [
    '/signup/finish-signup',
    '/login',
    '/login/mfa',
    '/unsubscribe',
    '/signup/complete',
  ];
  const displaySearchBar =
    !hideSearchBarUrls.includes(pathname) &&
    !isCheckoutPage &&
    !isMobile &&
    !isProviderProfile;
  const transparentVariant = isHomepage && !isMobile;

  const onSearch = (query: string) => {
    navigate(`/pet/groomers/chicago?query=${query}`);
  };

  return (
    <>
      <div
        className={classNames(
          'navbar flex-row justify-center w-100 b-white',
          transparentVariant ? 'navbar--transparent pos-absolute' : 'pos-fixed',
          isMobileMenuOpen && 'b-blue',
        )}
      >
        {!isCheckoutPage && !isMobileMenuOpen && (
          <Link
            to={`${localStorage.getItem('homepageUrl') || '/'}?home=true`}
            className="navbar-logo pos-absolute"
          >
            <Icon
              name="pawcare"
              width={125}
              height="100%"
              outterColor={transparentVariant ? 'white' : '#150828'}
              fill={transparentVariant ? '#150828' : 'white'}
            />
          </Link>
        )}
        {!token && !isMobile && isHomepage && (
          <button
            className="daycare flex-row pointer pos-absolute f16 text-white"
            onClick={() => openDaycareModal(true)}
          >
            Daycare{' '}
            <span className="coming-soon ml-10 b-white text-black">
              Coming soon
            </span>
          </button>
        )}
        {displaySearchBar && (
          <SearchBar
            onSearch={onSearch}
            value=""
            className="navbar-searchbar"
            variant={transparentVariant ? 'transparent' : ''}
          />
        )}
        {!isMobile ? <DesktopItems /> : <MobileItems />}
      </div>
      <ReactModal
        ariaHideApp={false}
        isOpen={isDaycareModalOpen}
        onRequestClose={() => openDaycareModal(false)}
        className="daycare-modal"
      >
        <DaycareModal openModal={openDaycareModal} />
      </ReactModal>
    </>
  );
}

export default Navbar;
