import { useState } from 'react';
import ReactModal from 'react-modal';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { Link, useLocation } from 'react-router-dom';

import DefaultAvatar from '@/atoms/default-avatar';
import PetSelector from '@/atoms/dropdown/pet-selector';
import CreatePetDetails from '@/pages/create-pet/create-pet-details';
import { clearForm } from '@/store/reducers/create-pet-form';
import { setActivePet } from '@/store/reducers/pet-appointments';
import { isNotEmptyArray } from '@/util';

function LoggedIn() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { uuid } = useAppSelector((state) => state.user.data);
  const { activePet } = useAppSelector((state) => state.petAppointments);
  const { data: userData } = useAppSelector((state) => state.user);
  const pets = userData.animals;
  const [isAddPetModalOpen, setIsAddPetModalOpen] = useState(false);

  const hasMultiplePets = isNotEmptyArray(pets) && pets.length > 1;
  const hasNoPets = !isNotEmptyArray(pets) || pets.length === 0;

  // Define the URL where you want to hide the PetSelector
  const hideSelectorUrls = [
    '/booking-select-pet',
    '/booking-select-groomer',
    '/booking-select-slot',
    '/booking-payment',
    '/signup/address',
    '/signup/complete',
  ];

  // Check if the current URL matches the specified  or if the user does not have multiple pets
  const shouldHideSelector =
    hideSelectorUrls.includes(location.pathname) || !hasMultiplePets;

  const handlePetSelect = (selectedItem: any) => {
    if (selectedItem.id === 'add-pet') {
      toggleAddPetModal();
    } else {
      dispatch(setActivePet(selectedItem));
    }
  };

  const toggleAddPetModal = () => {
    dispatch(clearForm());
    setIsAddPetModalOpen((prevState) => !prevState);
  };

  return (
    <>
      <Link
        className="navbar-avatar"
        to={hasNoPets ? '/create-pet' : `/user/${uuid}/pet/${activePet?.uuid}`}
      >
        <DefaultAvatar
          size="small"
          avatar={activePet?.avatarUrls?.mobile?.small}
        />
      </Link>
      {!shouldHideSelector && (
        <PetSelector
          size="small"
          onClick={handlePetSelect}
          disableAddPetAction
        />
      )}
      {isAddPetModalOpen && (
        <ReactModal
          ariaHideApp={false}
          isOpen={isAddPetModalOpen}
          onRequestClose={() => toggleAddPetModal()}
        >
          <CreatePetDetails />
        </ReactModal>
      )}
    </>
  );
}

export default LoggedIn;
